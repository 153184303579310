import React from "react";
import Seo from "../../components/seo";
import { Link } from "gatsby";
import Carousel from "../../components/layout/Carousel";
import useIsInViewport from "../../hooks/useIsInViewport";
// import Tooltip from '../plugins/Tooltip';

/* START: images import */
import magnus_art_logo from "../../img/services/magnus_art_logo.svg";
import elmwood from "../../img/services/elmwood.svg";
import zpevnik from "../../img/services/zpevnik.svg";
import mouse_app from "../../img/services/mouse_app.svg";
import kohinoor from "../../img/services/kohinoor.svg";
import project_royal_mail from "../../img/services/project_royal_mail.svg";
import project_mondelez from "../../img/services/project_mondelez.svg";
import experience_react from "../../img/services/react.svg";
import experience_angular from "../../img/services/angular.svg";
import experience_laravel from "../../img/services/laravel.svg";
import experience_gitlab from "../../img/services/gitlab.svg";
import experience_electron from "../../img/services/electron.svg";
import experience_gatsby from "../../img/services/gatsby.svg";
import experience_trello from "../../img/services/trello.svg";
import experience_android from "../../img/services/android.svg";
import experience_ios from "../../img/services/ios.svg";
import experience_nodejs from "../../img/services/nodejs.svg";
import experience_slack from "../../img/services/slack.svg";
import experience_mysql from "../../img/services/mysql.svg";
import experience_atlassian from "../../img/services/atlassian.svg";
import joalis_logo from "../../img/services/logo-joalis.png";
import mpm from "../../img/services/mpm.svg";
import smreflex from "../../img/services/smreflex.svg";
import firebase from "../../img/services/firebase.svg";
import aws from "../../img/services/aws.svg";
import nextjs from "../../img/services/nextjs.svg";
import swagger from "../../img/services/swagger.svg";
import postman from "../../img/services/postman.svg";
import docker from "../../img/services/docker.svg";
import sketch from "../../img/services/sketch.svg";

/* END: images import */

const carouselItems = [
    { src: experience_react, alt: "React Native" },
    { src: experience_angular, alt: "Angular" },
    { src: experience_laravel, alt: "Laravel" },
    { src: experience_nodejs, alt: "NodeJS" },
    { src: experience_gatsby, alt: "Gatsby" },
    { src: experience_electron, alt: "Electron" },
    // { src: experience_gulp, alt: "Gulp" },
    // { src: experience_cordova, alt: "Cordova" },
    { src: experience_ios, alt: "iOS" },
    { src: experience_atlassian, alt: "Atlassian" },
    { src: experience_android, alt: "Android" },
    { src: experience_gitlab, alt: "GitLab" },
    { src: experience_trello, alt: "Trello" },
    { src: experience_slack, alt: "Slack" },
    // { src: experience_docker, alt: "Docker" },
    { src: experience_mysql, alt: "MySQL" },
    // { src: jenkins, alt: "Jenkins" },
    { src: aws, alt: "AWS" },
    { src: firebase, alt: "Firebase" },
    { src: nextjs, alt: "Nextjs" },
    { src: swagger, alt: "Swagger" },
    { src: postman, alt: "Postman" },
    { src: docker, alt: "Docker" },
    { src: sketch, alt: "Sketch" },
    // { src: aws, alt: "AWS" },
    // { src: protractor, alt: "Protractor" },
    // { src: mobx, alt: "Mob X" },
    //        {src:arduino, alt:"Arduino"},
    // { src: jasmine, alt: "Jasmine" },
    // { src: raspi, alt: "Raspberry Pi" }
];

const Intro = ({ indx, setObserverRef }) => (
    <article className="article" id="SSG" ref={setObserverRef} data-observer={indx}>
        <article className="text">
            <h2 className="line animate-1">Gatsby &amp; Next JS?</h2>
            <p className="animate-2">
                Gatsby JS je generátor statických stránek. Next JS dokáže jak stránky generovat, tak je i dynamicky renderovat. To má však vyšší poždavky na hosting. Oba frameworky
                se samozřejmě dokáží připojit k externímiu API a oba excelují v rychosti načítání a Seo. Jestli se v tom trochu ztrácíte, nevěšte hlavu. Napište nám a určite spolu
                najdeme to pravé řešení pro Váš projekt.
            </p>
        </article>
        <article>
            <h3 className="animate-3">Příklady Gatsby a Next JS projektů</h3>
            <div className="examples animate-4">
                <div>
                    <img className="animate-5" src={mpm} style={{ margin: "28px", height: "78px" }} alt="DrMouse" />
                    <h4 className="line project animate-6">Online katalog</h4>
                    <p className="animate-7">
                        Next JS nám umožnil dostat to nejlepší z obou světů. Výkon předgenerovaných stránek bez nutnosti přegenerovávat celý web znovu a znovu. Část webu, která se
                        často nemění je předgenerována, zatímco dynamický obsah zůastává vždy aktuální.
                    </p>
                    <a className="animate-8" href="https://www.mobilephonemuseum.com" target="_blank" rel="noopener noreferrer">
                        Navštivte web
                    </a>
                </div>
                <div>
                    <img className="animate-5" src={joalis_logo} style={{ margin: "40px", maxWidth: "150px", marginTop: "10px" }} alt="Joalis" />
                    <h4 className="line project animate-6">Technologická chuťovka</h4>
                    <p className="animate-7">
                        Plnotučná{" "}
                        <a href="https://www.shop8.cz/" target="_blank" rel="noopener noreferrer">
                            shop8
                        </a>{" "}
                        experience — Vyladěná administrace, obsáhlý GraphQL endpoint, S3, robustní pipeline, super rychlý web, frontshop &amp; mobile app. Podpora multi lokalizace,
                        multi varianty prodkutů, multi měny, multi platební brány, multi... Však vy už tušíte.
                    </p>
                    <a className="animate-8" href="https://www.joalis.cz/" target="_blank" rel="noopener noreferrer">
                        Navštivte web
                    </a>
                </div>
            </div>
        </article>
    </article>
);

const JamStack = ({ indx, setObserverRef }) => (
    <article className="article" id="jamstack" ref={setObserverRef} data-observer={indx}>
        <article className="text">
            <h2 className="line animate-1">Jamstack aplikace</h2>
            <p className="animate-2">
                Jamstack je moderní architektura webových aplikací, která používá JavaScript, flexibilně použitelné API a značkovacý jazyk (tradičně html). Základní myšlenka je
                vytvořit obecný backend a k němu pak napojit výkonný frontend. Výsledkem je excelentní uživatelský zážitek.
            </p>
        </article>
        <h3 className="animate-3">Příklady Jamstack aplikací</h3>
        <article className="examples animate-4">
            <div>
                <img className="animate-5" src={magnus_art_logo} style={{ margin: "25px", maxWidth: "300px" }} alt="MagnusArt" />
                <h4 className="line project animate-6">Představení uměleckých děl </h4>
                <p className="animate-7">
                    Klient si přál spravovat obsah webu přes svůj oblíbený CMS, ale bylo velmi pravděpodobné, že stránky nebudou aktualizované příliš často. To je ideální scenář
                    pro použití generátoru statických stránek, který by si pro data šahal do externí Wordpress instance.
                </p>
                <a className="animate-8" href="https://magnusart.cz" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            <div>
                <img className="animate-5" src={elmwood} style={{ margin: "32px", width: "240px" }} alt="Elmwood" />
                <h4 className="line project animate-6">Animovaná prezentace</h4>
                <p className="animate-7">
                    Přední globální agentura zaměřující se na branding si u nás nechala naprogramovat jejich rozpohybovanou webovou prezentaci. Vše běží na platformě Gatsby cloud.
                </p>
                <a className="animate-8" href="https://www.elmwood.com/" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
                {/* <a href="mailto:hello@code8.cz">Vyžádejte si ukázku kódu</a> */}
            </div>
        </article>
    </article>
);

const MobileApps = ({ indx, setObserverRef }) => (
    <article className="article" id="mobileApps" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">Mobilní aplikace</h2>
            <p className="animate-2">
                Sdílení až 80 % kódu mezi Androidem a iOS vám ušetří spoustu nákladů dnes i při dalším vývoji v budoucnosti. Spoléháme na dvě hybridní technologie - React Native a
                NativeScript.
            </p>
        </div>
        <h3 className="animate-3">Příklady Mobilních aplikací</h3>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={mouse_app} style={{ margin: "40px", width: "85px" }} alt="Dr Mouse App" />
                <h4 className="line project animate-6">Veterinář s přehledem</h4>
                <p className="animate-7">Dr. Mouse je mobilní aplikace pro Android i iOS. Poskytne vám přehled veterinárních ordinací v celé České republice. A to i offline.</p>
                <a className="animate-8" href="https://www.drmouse.cz/aplikace" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            <div>
                <img className="animate-5" src={smreflex} style={{ margin: "45px", height: "70px" }} alt="Debrett’s wedding planner" />
                <h4 className="line project animate-6">SM Reflex</h4>
                <p className="animate-7">
                    Program společnosti Zentiva je zaměřen na pacienty s onemocněním roztroušené sklerózy. Smyslem aplikace je pomoci pacientovi zvládnout jeho onemocnění a
                    poskytovat přínosné informace, které by pomohli zlepšit kvalitu jeho života.
                </p>
                <a className="animate-8" href="https://rsreflex.cz" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
        </div>
    </article>
);

const BeSpoke = ({ indx, setObserverRef }) => (
    <article className="article" id="bespoke" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">API, backendová řešení &amp; DevOps</h2>
            <p className="animate-2">
                Programujeme v NodeJS a PHP. Obvykle používáme relační databáze, REST api provádíme úkony typu sytémový audit, optimalizace, migrace obsahu atp. Máme značnou
                zkušenost na poli DevOps, kontinuální integrace, testování a automatizovaného deploymentu.
            </p>
        </div>
        <h3 className="animate-3">Příklady řešení</h3>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={project_royal_mail} style={{ margin: "40px", width: "150px" }} alt="Royal Mail PAF" />
                <h4 className="line project animate-6">Royal Mail PAF</h4>
                <p className="animate-7">
                    S našimi dlouholetými partnery z{" "}
                    <a href="http://www.dataadvance.co.uk" target="_blank" rel="noopener noreferrer">
                        Data advance
                    </a>{" "}
                    máme za sebou několik úspěšných projektů, například Royal Mail's PAF Licensing Centre.
                </p>
                <a className="animate-8" href="http://www.poweredbypaf.com/licensing-centre/" target="_blank" rel="noopener noreferrer">
                    Navštivte web
                </a>
            </div>
            <div>
                <img className="animate-5" src={project_mondelez} style={{ margin: "65px", maxWidth: "300px" }} alt="Mondelez" />
                <h4 className="line project animate-6">Interaktivní mapa</h4>
                <p className="animate-7">
                    Integrací APIs jednotlivých sociálních sítí a Google Maps jsme vytvořili nástroj na podrobný přehled značek a jejich aktivity na sociálnch sítích.
                </p>
                <a className="animate-8" href="mailto:hello@code8.cz">
                    Vyžádejte si ukázku kódu
                </a>
            </div>
        </div>
    </article>
);

const Product = ({ indx, setObserverRef }) => (
    <article className="article" id="product" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">Idea making a produktový design</h2>
            <p className="animate-2">
                Každý produkt má svůj vlastní příběh a my jsme rádi jejich součástí. Můžeme být Vaším silným partnerem v dobách, kdy nápady teprve vznikají, nebo se můžete se opřít
                o naše zkušenosti ve chvíli, kdy Váš produkt již dostává tvar. Často jsou to náročné chvíle, ale my jsme v nich byli mnohokrát. Proto jsme přesvědčeni, že můžeme
                být správný partner.
            </p>
        </div>
        <h3 className="animate-3">Příklady produktů jichž jsme součástí</h3>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={zpevnik} style={{ margin: "20px", height: "130px" }} alt="Zpevnik" />
                <h4 className="line project animate-6">Písničky do kapsy</h4>
                <p className="animate-7">
                    Chtěli jsme zpěvník, který bude pohodlný, jednoduchý a rychlý. Nic takového, co by nám vyhovovalo jsme nenašli. Tak jsme si vytvořili vlastní. Od nápadu, přes
                    design až po samotnou realizaci. Je zdarma a určený všem, kdo rádi brnkají.
                </p>
                <a className="animate-8" href="https://www.mujzpevnik.cz/" target="_blank" rel="noopener noreferrer">
                    Navštívit web
                </a>
            </div>
            <div>
                <img className="animate-5" src={kohinoor} style={{ margin: "20px", width: "150px" }} alt="Koh-i-noor" />
                <h4 className="line project animate-6">Animovaná jednostránka</h4>
                <p className="animate-7">
                    Společnosti KOH-I-NOOR Deutschland GmbH nevyhovoval současný design a propagace produktové řady antibakteriálních pastelek. Rozhodli jsme se tedy vrátit se na
                    úplný začátek a vytvořit nový design komunikace této produktové řady, který bude daleko více zaměřen na děti a jejich rodiče.
                </p>
                <a className="animate-8" href="http://flanaganlawrence.com" target="_blank" rel="noopener noreferrer">
                    Zatím to ještě vaříme
                </a>
            </div>
        </div>
    </article>
);

const Experience = ({ indx, setObserverRef }) => (
    <article className="article" id="experience" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">A dál?</h2>
            <p className="animate-2">Máme dlouholeté zkušenosti z následujícími technologiemi. </p>
        </div>
        <div id="experienced" className="animate-3">
            <Carousel items={carouselItems} />
        </div>
        <div className="clear"></div>
        <div className="text animate-4" style={{ position: "relative", zIndex: 500 }}>
            <br />
            <p>
                Přečtěte si pár zajímavostí o nás a <Link to="/cz/o-nas">o nás</Link>
            </p>
        </div>
    </article>
);

const sections = [Intro, JamStack, MobileApps, BeSpoke, Product, Experience];

const Services = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <Seo title="Co umíme" lang="cs" />
            <div>
                {sections.map((Component, index) => (
                    <Component key={index} indx={index} setObserverRef={setRef} />
                ))}
            </div>
            <div className="clear"></div>
        </>
    );
};
export default Services;
